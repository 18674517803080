import React, { FC, useMemo } from 'react'

import { Container, Image, Item, Track } from './ImagesCarousel.styles'
import { calculateWidth, parseProps } from './utils'

type Props = { componentProps: string }

export const ImagesCarousel: FC<Props> = ({ componentProps }) => {
  const {
    items,
    duration,
    gap,
    itemWidth,
    itemPadding,
    itemBackground,
    height,
    pauseOnHover
  } = useMemo(() => parseProps(componentProps), [componentProps])

  return (
    <Container data-block="images-carousel" height={height}>
      <Track
        gap={gap}
        width={calculateWidth(items.length, itemWidth, gap)}
        duration={duration}
        pauseOnHover={pauseOnHover}
      >
        {items.concat(items).map((item, index) => (
          <Item
            key={index}
            itemBackground={itemBackground}
            itemPadding={itemPadding}
            itemWidth={itemWidth}
            as={!!item.target?.length ? 'a' : 'div'}
            {...(!!item.target?.length && { href: item.target })}
          >
            <Image
              src={item.image.url}
              alt={item.image.alt}
              title={item.image.title}
            />
          </Item>
        ))}
      </Track>
    </Container>
  )
}
