import styled, { css } from 'styled-components'

type ContainerProps = { height: number }

export const Container = styled.section<ContainerProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 25%,
    black 75%,
    transparent 100%
  );
`

type TrackProps = {
  gap: number
  width: number
  duration: number
  pauseOnHover: boolean
}

export const Track = styled.div<TrackProps>`
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap}px;
  height: 100%;
  width: 200%;
  animation: slide ${({ duration }) => duration}ms linear infinite;

  ${({ pauseOnHover }) =>
    pauseOnHover &&
    css`
      &:hover {
        animation-play-state: paused;
      }
    `}

  @keyframes slide {
    0% {
      left: 0;
    }

    100% {
      left: ${({ width }) => -width}px;
    }
  }
`

type ItemProps = {
  itemWidth: number
  itemPadding: number
  itemBackground: string
}

export const Item = styled.div<ItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ itemWidth }) => itemWidth}px;
  height: 100%;
  padding: ${({ itemPadding }) => itemPadding}px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: ${({ itemBackground }) => itemBackground};
  overflow: hidden;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`
