import { ImagesCarouselProps } from './types'

export function calculateWidth(
  itemsCount: number,
  itemWidth: number,
  gap: number
): number {
  return itemWidth * itemsCount + gap * itemsCount
}

export function parseProps(componentProps: string): ImagesCarouselProps {
  return { ...DEFAULT_PROPS, ...JSON.parse(componentProps) }
}

const DEFAULT_PROPS = {
  items: [],
  duration: 30000,
  gap: 64,
  itemWidth: 320,
  itemPadding: 32,
  itemBackground: '#ffffff',
  height: 320,
  pauseOnHover: true,
}
